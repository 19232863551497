<template>
  <div v-if="taskList" class="sub-tasks">
    <sub-task
      v-for="(task, index) in taskList"
      class="sub-tasks__item"
      :task="task"
      :key="index + 1"
      :index="index + 1"
    />
  </div>
</template>

<script>
const SubTask = () => import('./SubTask.vue');

export default {
  name: 'SubTaskList',
  components: { SubTask },
  props: {
    taskList: {
      type: Array,
      default: undefined,
    },
  },
};
</script>
